import React, { useCallback, useState } from 'react';
import Input from 'components/input/input';
import Popover from 'components/popover/popover';
import { caretFilledSmallDown } from 'constants/index';
import { TIME_ZONES } from 'components/remote/timezoneConstants';
import { setDeviceTime } from 'apis';
import { toaster } from 'helpers';
import toast from 'react-hot-toast';
import styles from './timezoneDropdown.module.scss';

interface iProps {
  className?: string;
  options: {
    id: string;
    timezone: string;
  }[];
  choosedTimezone: null | { id: string; timezone: string };
  onChange: (a: { id: string; timezone: string }) => void;
  screenId: string;
}

function TimezoneDropdown(props: iProps): JSX.Element {
  const {
    className = '',
    options,
    onChange,
    choosedTimezone = TIME_ZONES[0],
    screenId = '',
  } = props;
  const [search, setSearch] = useState('');
  const { timezone = '' } = choosedTimezone || {};

  const onChoose = useCallback(
    async (choosed: { id: string; timezone: string }) => {
      toaster.loading('Updating device time. Please wait');
      const response = await setDeviceTime({
        screenId,
        timeZone: choosed.timezone,
      });
      if (response) {
        toast.dismiss();
        setSearch('');
        onChange(choosed);
        toaster.success('Updated the device time!');
      }
      if (!response) {
        toast.dismiss();
        toaster.error(
          'Unable to update the device time. Please try again after sometime.'
        );
      }
    },
    [onChange, screenId]
  );
  return (
    <div className={`${className} ${styles.timeZoneDropdownContainer}`}>
      <Popover
        popoverClassName={styles.popoverClassName}
        popoverComponent={
          <ul className={styles.viewOptions}>
            {options
              .filter((opt) =>
                opt.timezone
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              )
              .map((opt) => (
                <li
                  key={opt.id}
                  className={styles.option}
                  onClick={() => onChoose(opt)}
                >
                  {opt.timezone}
                </li>
              ))}
          </ul>
        }
      >
        <div className={styles.dropdownChoosedOption}>
          {/* {icon && <img className={styles.icon} src={icon} alt="" />} */}
          <Input
            className={styles.timezoneInputField}
            placeholder={timezone}
            value={search}
            onChange={(e) => {
              const val = e.target.value;
              if (val.length < 150) setSearch(e.target.value);
            }}
            hideBorder
          />
          <img
            className={styles.dropdownIcon}
            src={caretFilledSmallDown}
            alt=""
          />
        </div>
      </Popover>
    </div>
  );
}

TimezoneDropdown.defaultProps = {
  className: '',
};

export default TimezoneDropdown;
