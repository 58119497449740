import { uuid } from 'helpers';

export const TIME_ZONES = [
  {
    id: uuid(),
    timezone: 'America/Manaus',
  },
  {
    id: uuid(),
    timezone: 'America/Monterrey',
  },
  {
    id: uuid(),
    timezone: 'America/Montevideo',
  },
  {
    id: uuid(),
    timezone: `America/Santiago`,
  },
  {
    id: uuid(),
    timezone: `America/Tijuana`,
  },
  {
    id: uuid(),
    timezone: `Asia/Amman`,
  },
  {
    id: uuid(),
    timezone: `Asia/Baghdad`,
  },
  {
    id: uuid(),
    timezone: `Asia/Baku`,
  },
  {
    id: uuid(),
    timezone: `Asia/Bangkok`,
  },
  {
    id: uuid(),
    timezone: `Asia/Beirut`,
  },
  {
    id: uuid(),
    timezone: `Asia/Colombo`,
  },
  {
    id: uuid(),
    timezone: `Asia/Dhaka`,
  },
  {
    id: uuid(),
    timezone: `Asia/Irkutsk`,
  },
  {
    id: uuid(),
    timezone: `Asia/Jerusalem`,
  },
  {
    id: uuid(),
    timezone: `Asia/Kabul`,
  },
  {
    id: uuid(),
    timezone: `Asia/Kamchatka`,
  },
  {
    id: uuid(),
    timezone: `Asia/Karachi`,
  },
  {
    id: uuid(),
    timezone: `Asia/Kathmandu`,
  },
  {
    id: uuid(),
    timezone: `Asia/Kolkata`,
  },
  {
    id: uuid(),
    timezone: `Asia/Krasnoyarsk`,
  },
  {
    id: uuid(),
    timezone: `Asia/Kuala_Lumpur`,
  },
  {
    id: uuid(),
    timezone: `Asia/Kuwait`,
  },
  {
    id: uuid(),
    timezone: `Asia/Magadan`,
  },
  {
    id: uuid(),
    timezone: `Asia/Muscat`,
  },
  {
    id: uuid(),
    timezone: `Asia/Novosibirsk`,
  },
  {
    id: uuid(),
    timezone: `Asia/Rangoon`,
  },
  {
    id: uuid(),
    timezone: `Asia/Seoul`,
  },
  {
    id: uuid(),
    timezone: `Asia/Taipei`,
  },
  {
    id: uuid(),
    timezone: `Asia/Tashkent`,
  },
  {
    id: uuid(),
    timezone: `Asia/Tbilisi`,
  },
  {
    id: uuid(),
    timezone: `Asia/Tehran`,
  },
  {
    id: uuid(),
    timezone: `Asia/Tokyo`,
  },
  {
    id: uuid(),
    timezone: `Asia/Ulaanbaatar`,
  },
  {
    id: uuid(),
    timezone: `Asia/Vladivostok`,
  },
  {
    id: uuid(),
    timezone: `Asia/Yakutsk`,
  },
  {
    id: uuid(),
    timezone: `Asia/Yekaterinburg`,
  },
  {
    id: uuid(),
    timezone: `Asia/Yerevan`,
  },
  {
    id: uuid(),
    timezone: `Atlantic/Azores`,
  },
  {
    id: uuid(),
    timezone: `Atlantic/Cape_Verde`,
  },
  {
    id: uuid(),
    timezone: `Australia/Perth`,
  },
  {
    id: uuid(),
    timezone: `Australia/Adelaide`,
  },
  {
    id: uuid(),
    timezone: `Australia/Brisbane`,
  },
  {
    id: uuid(),
    timezone: `Australia/Canberra`,
  },
  {
    id: uuid(),
    timezone: `Australia/Darwin`,
  },
  {
    id: uuid(),
    timezone: `Australia/Hobart`,
  },
  {
    id: uuid(),
    timezone: `Canada/Atlantic`,
  },
  {
    id: uuid(),
    timezone: `Canada/Newfoundland`,
  },
  {
    id: uuid(),
    timezone: `Canada/Saskatchewan`,
  },
  {
    id: uuid(),
    timezone: `Etc/GMT+1`,
  },
  {
    id: uuid(),
    timezone: `Etc/GMT+4`,
  },
  {
    id: uuid(),
    timezone: `Etc/GMT-12`,
  },
  {
    id: uuid(),
    timezone: `Etc/GMT-2`,
  },
  {
    id: uuid(),
    timezone: `Europe/Amsterdam`,
  },
  {
    id: uuid(),
    timezone: `Europe/Athens`,
  },
  {
    id: uuid(),
    timezone: `Europe/Belgrade`,
  },
  {
    id: uuid(),
    timezone: `Europe/Helsinki`,
  },
  {
    id: uuid(),
    timezone: `Europe/London`,
  },
  {
    id: uuid(),
    timezone: `Europe/Minsk`,
  },
  {
    id: uuid(),
    timezone: `Europe/Moscow`,
  },
  {
    id: uuid(),
    timezone: `Europe/Paris`,
  },
  {
    id: uuid(),
    timezone: `Europe/Sarajevo`,
  },
  {
    id: uuid(),
    timezone: `Pacific/Auckland`,
  },
  {
    id: uuid(),
    timezone: `Pacific/Fiji`,
  },
  {
    id: uuid(),
    timezone: `Pacific/Guam`,
  },
  {
    id: uuid(),
    timezone: `Pacific/Samoa`,
  },
  {
    id: uuid(),
    timezone: `Pacific/Tongatapu`,
  },
  {
    id: uuid(),
    timezone: `Universal`,
  },
  {
    id: uuid(),
    timezone: `US/Alaska`,
  },
  {
    id: uuid(),
    timezone: `US/Arizona`,
  },
  {
    id: uuid(),
    timezone: `US/Central`,
  },
  {
    id: uuid(),
    timezone: `US/Eastern`,
  },
  {
    id: uuid(),
    timezone: `US/Hawaii`,
  },
  {
    id: uuid(),
    timezone: `US/Mountain`,
  },
  {
    id: uuid(),
    timezone: `US/Pacific`,
  },
];
