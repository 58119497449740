/* eslint-disable jsx-a11y/anchor-is-valid */
import { getScreenById, remoteActions } from 'apis';
import Slider from 'components/range-slider/slider';
import Power from 'components/svg/power';
import {
  informationIcon,
  LoadingCircle,
  logoBoxIcon,
  powerIcon,
  RemoteIcon,
} from 'constants/index';
import { useNavigate } from 'react-router-dom';
import { RemoteActions } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { iScreenData } from 'types';
import { Tooltip } from 'react-tooltip';
import styles from './remote.module.scss';
import TimezoneDropdown from './components/timezon-dropdown/timezoneDropdown';
import { TIME_ZONES } from './timezoneConstants';

interface iProps {
  className?: string;
  screenId: string;
  // onClose?: any;
}

function Controller(props: iProps): JSX.Element {
  const { className, screenId } = props;
  const [screenData, setScreenData] = useState<iScreenData>();
  const [standByButton] = useState(1);
  const [choosedTimezone, setChoosedTimezone] = useState<null | {
    id: string;
    timezone: string;
  }>(null);
  const [isRestartingApp, setIsRestartingApp] = useState<boolean>(false);
  const [isRebooting, setIsRebooting] = useState<boolean>(false);
  const navigate = useNavigate();

  const reboot = useCallback(async () => {
    await remoteActions(
      { action: RemoteActions.Reboot, screenId },
      setIsRebooting,
      navigate
    );
  }, [screenId, navigate]);

  const displayOff = useCallback(async () => {
    await remoteActions(
      { action: RemoteActions.DisplayOff, screenId },
      () => {},
      navigate
    );
  }, [screenId, navigate]);

  const displayOn = useCallback(async () => {
    await remoteActions(
      { action: RemoteActions.DisplayOn, screenId },
      () => {},
      navigate
    );
  }, [screenId, navigate]);

  const restartApp = useCallback(async () => {
    await remoteActions(
      { action: RemoteActions.RestartApp, screenId },
      setIsRestartingApp
    );
  }, [screenId]);

  const getScreen = useCallback(async () => {
    const response = await getScreenById({ id: screenId }, () => {});
    if (!response) return;
    console.log(response, 'screens response');
    setScreenData(response);
    const { timeZone: responseTimeZone = '' } = response || {};
    const selectedTimezone = TIME_ZONES.find(
      (timeZone) => timeZone.timezone === responseTimeZone
    );
    // console.log(selectedTimezone, 'selected timezone');
    if (selectedTimezone) setChoosedTimezone(selectedTimezone);
  }, [screenId, setScreenData]);

  useEffect(() => {
    getScreen();
  }, [screenId, getScreen]);

  const brightnessChangeHandler = useCallback(
    async (value: number) => {
      await remoteActions(
        { action: RemoteActions.Brightness, screenId, brightness: value },
        () => {},
        navigate
      );
    },
    [screenId, navigate]
  );

  const volumeChangeHandler = useCallback(
    async (value: number) => {
      await remoteActions(
        { action: RemoteActions.Volume, screenId, volume: value },
        () => {},
        navigate
      );
    },
    [screenId, navigate]
  );

  return (
    <div className={`${className} ${styles.controllerWrapper}`}>
      <div className={styles.sectionHeading}>
        <img src={RemoteIcon} alt="" className={styles.svg} />
        <p>Device Remote</p>
      </div>

      <div className={styles.action}>
        <p>Reboot Device</p>
        {!isRebooting && (
          <button type="button" className={styles.reboot} onClick={reboot}>
            <img src={powerIcon} alt="" /> Reboot
          </button>
        )}
        {isRebooting && (
          <button type="button" className={styles.rebooting}>
            <img src={LoadingCircle} alt="" className={styles.loader} />
            Rebooting
          </button>
        )}
      </div>

      <div className={styles.action}>
        <p>Standby</p>
        <div className={styles.standbyBtnGroup}>
          <button
            type="button"
            className={`${styles.on} ${standByButton === 1 && styles.active}`}
            onClick={displayOn}
          >
            <Power />
            On
          </button>
          <button
            type="button"
            className={`${styles.off} ${standByButton === 2 && styles.active}`}
            onClick={displayOff}
          >
            <Power />
            Off
          </button>
        </div>
      </div>
      <div className={`${styles.action} ${styles.brightness}`}>
        <p>Brightness</p>
        <Slider
          value={Number(screenData?.brightness)}
          onChange={brightnessChangeHandler}
        />
      </div>
      <div className={`${styles.action} ${styles.volume}`}>
        <p>Volume</p>
        <Slider
          value={Number(screenData?.volume)}
          onChange={volumeChangeHandler}
        />
      </div>
      <div
        className={`${styles.action} ${styles.volume} ${styles.timeZoneDropDown}`}
      >
        <div className={styles.informationiconContainer}>
          <p>Set Timezone</p>
          <a className="my-anchor-element">
            <img className={styles.icon} src={informationIcon} alt="" />{' '}
          </a>
          <Tooltip
            className={styles.tooltipStyles}
            anchorSelect=".my-anchor-element"
          >
            If there is a time mismatch in the device, reselect the time zone to
            fix the time difference.
          </Tooltip>
        </div>

        <TimezoneDropdown
          className={styles.remoteTimeZone}
          screenId={screenId}
          choosedTimezone={choosedTimezone}
          onChange={setChoosedTimezone}
          options={TIME_ZONES}
        />
      </div>
      <hr className={styles.divider} />
      <div className={styles.sectionHeading}>
        <img className={styles.svg} src={logoBoxIcon} alt="" />
        <p>Screenz Remote</p>
      </div>
      <div className={`${styles.action} ${styles.restartPlayer}`}>
        <p>Restart App</p>
        {!isRestartingApp && (
          <button type="button" className={styles.restart} onClick={restartApp}>
            <img src={powerIcon} alt="" /> Restart
          </button>
        )}
        {isRestartingApp && (
          <button type="button" className={styles.restarting}>
            <img src={LoadingCircle} alt="" className={styles.loader} />{' '}
            Restarting
          </button>
        )}
      </div>
    </div>
  );
}

Controller.defaultProps = {
  className: '',
};

export default Controller;
